import React, { useContext } from 'react'
import { observer } from 'mobx-react-lite'
import { values } from 'mobx'

import StoreContext from '../services/store'
import { BoardType } from '../models/board'

// Page

const BoardPage = () => <BoardsQuery>{({ boards }) => <BoardList boards={boards} />}</BoardsQuery>
// const BoardPage = () => <BoardsQuery />

// Query

// Injected Props
type BoardQueryAPI = {
  boards: ReadonlyArray<BoardType>
}

type BoardQueryProps = {
  children(props: BoardQueryAPI): JSX.Element
}

// const BoardsQuery = ({ children }: ReactNode) => {
const BoardsQuery = observer(({ children }: BoardQueryProps) => {
  // const BoardsQuery = observer(() => {
  const store = useContext(StoreContext)
  store.boardStore.loadBoards()
  const { boards } = store.boardStore
  // return <BoardList boards={values(boards)} />
  return children({ boards: values(boards) })
  // return <Observer>{() => <BoardList boards={values(boards)} />}</Observer> (if not using global observer in this component)
})

// Dumbs components

type BoardListProps = {
  boards: ReadonlyArray<BoardProps>
}

type BoardProps = {
  id: string
  name: string
  setName(newName: string): void
}

const BoardList = observer(({ boards }: BoardListProps) => {
  const setName = React.useCallback((board, newName: string) => {
    board.setName(newName)
  }, [])
  return (
    <div>
      {boards.map(board => (
        <Board key={`board-${board.id}`} {...board} />
      ))}
    </div>
  )
})

// Maybe Generize BoardListPure as a list render prop so that we can use custom children render function and better test it (and re-use it...)

const Board = observer((props: BoardProps) => <div onClick={() => props.setName('coucou')}>{props.name}</div>)

export default BoardPage
